* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html, body {
    height: 100%;
}
body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    max-width: 600px;
    width: 100%;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #4A90E2;
}
.search-container {
    position: relative;
    margin-bottom: 20px;
}

input[type="text"] {
    flex: 1;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
input[type="text"]:focus {
    border-color: #4A90E2;
    outline: none;
}
.results {
    margin-top: 20px;
}

.results ul {
    list-style-type: none;
    padding: 0;
}

.results li {
    padding: 10px;
    background: #f9f9f9;
    border: 1px solid #eee;
    margin-bottom: 10px;
    border-radius: 4px;
    transition: background 0.3s;
}

.results li:hover {
    background: #e6f7ff;
}

.navigation { text-align: center;
}
.suggestions {
    border: 1px solid #ccc;
    max-height: 150px;
    overflow-y: auto;
    display: none;
    position: absolute; 
    background: #fff;
    z-index: 1000;
    width: calc(100% - 22px); 
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    top: 100%; 
    left: 0; 
}

.suggestion-item {
    padding: 10px;
    cursor: pointer;
}

.suggestion-item:hover {
    background: #e6f7ff;
}
